import { PromotionPeriodFormat } from '@templates/promotions/interface';
export interface PromotionProps extends TranslationProps {
  prefix: string;
  promotions: PromotionMapResult[];
}
export interface PromotionState {
  section: SectionTH;
  promotionList: PromotionMapResult[];
  promotions: PromotionMapResult[];
}

export enum SectionEN {
  All = 'all',
  Coupons = 'coupon',
  RentalPromotions = 'rental_promotion',
  Other = 'other'
}

export enum SectionTH {
  All = 'ทั้งหมด',
  Coupons = 'คูปองส่วนลด',
  RentalPromotions = 'โปรโมชั่นรถเช่า',
  Other = 'ส่วนลดอื่นๆ'
}

export interface QueryResult {
  promotions: {
    edges: {
      node: {
        id: string;
        frontmatter: PromotionMapResult;
      };
    }[];
  };
}

export interface PromotionMapResult {
  description_th: string;
  description_en: string;
  book_period: PromotionPeriodFormat;
  pickup_period: PromotionPeriodFormat;
  banner_src_th: string;
  banner_src_en: string;
  promotion_show: boolean;
  kind: SectionTH;
  path: string;
  redirect_to_blog: boolean;
  activate: boolean;
  seo_title_th: string;
  seo_title_en: string;
}
