import { PureComponent } from 'react';
import Context from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import PromotionList from '@pages/promotions/sections/promotion-center';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { PromotionsProps } from './interface';
import { PageSection } from '@utils';
import './style.scss';

class PromotionsPage extends PureComponent<PromotionsProps> {
  render() {
    const { prefix } = this.props.pageContext;
    return (
      <Context>
        <Layout section={PageSection.Promotion} prefix={prefix} isTransparent={false}>
          <SEO
            prefix={prefix}
            title={'Drivehub ค้นหารถเช่า เช่ารถ ราคาถูกที่สุด จากบริษัทเช่ารถทั่วประเทศ'}
            description={
              'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถ รายวัน ราคาถูกในปี 2020 มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว'
            }
            keywords={'บริการ เช่ารถ, รถเช่า, ราคาถูก, รายวัน, ขับเอง, รถตู้, รถ suv, รถ eco car'}
            isHideReturningPopup
            disableAppBanner
          />
          <div className="promotions-page">
            <Container className="p-0">
              <Row className="justify-content-center">
                <PromotionList prefix={prefix} />
              </Row>
            </Container>
          </div>
        </Layout>
      </Context>
    );
  }
}

export default withTrans()(PromotionsPage);
