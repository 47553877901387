import { useEffect, createRef, FunctionComponent, useMemo } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { PromotionCardProps } from './interface';
import { PromotionPeriodFormat } from '@templates/promotions/interface';
import { canUseDOM, appendParamsCurrentPage } from '@utils';
import { WEB_BLOG_LINK } from '@config';
import {
  trackPromotionCardImg,
  trackPromotionCardMore,
  trackCarDealCardImpressionEcom,
  trackCarDealCardClickEcom
} from './tracking';
import './style.scss';

const DateTimeBox: FunctionComponent<{
  dateTime: PromotionPeriodFormat;
}> = ({ dateTime }) => {
  const today = moment().format('DD-MM-YYYY');
  const fromIsSameDate = moment(dateTime.from, 'DD-MM-YYYY').isSameOrBefore(moment(today, 'DD-MM-YYYY'));
  const fromText = fromIsSameDate ? 'วันนี้' : moment(dateTime.from, 'DD-MM-YYYY').add(543, 'year').format('DD MMM YY');
  const toText = moment(dateTime.to, 'DD-MM-YYYY').add(543, 'year').format('DD MMM YY');
  return <>{`${fromText} - ${toText}`}</>;
};

const PromotionCard: FunctionComponent<PromotionCardProps> = ({ prefix, promotion, cardPosition, t }) => {
  const PromotionCardRef = createRef<HTMLDivElement>();
  useEffect(() => {
    const PromotionCardObserver = new IntersectionObserver(
      (resp) => {
        if (resp[0].isIntersecting && resp[0].intersectionRatio === 1) {
          trackCarDealCardImpressionEcom({
            promotionID: promotion.path,
            promotionName: promotion.path,
            position: cardPosition,
            detail: promotion[`seo_title_${prefix}`]
          });
          PromotionCardObserver.disconnect();
        }
      },
      { root: null, threshold: 1 }
    );
    PromotionCardRef.current && PromotionCardObserver.observe(PromotionCardRef.current);
  }, []);

  const getPromotionPageURL = () => {
    const suffixPath = promotion.redirect_to_blog ? promotion.path : `promotions/${promotion.path}`;
    const promotionPageURL = `${prefix}/${suffixPath}`;
    const url = appendParamsCurrentPage(promotionPageURL);
    return url;
  };

  const handleOnClick = () => {
    trackCarDealCardClickEcom({
      promotionID: promotion.path,
      promotionName: promotion.path,
      position: cardPosition,
      detail: promotion[`seo_title_${prefix}`]
    });
    if (canUseDOM()) {
      const host = promotion.redirect_to_blog ? WEB_BLOG_LINK : window.location.origin;
      window.location.href = `${host}/${getPromotionPageURL()}`;
      return;
    }
    if (!promotion.redirect_to_blog) navigate(`/${prefix}/promotions/${promotion.path}`);
  };

  const imageData = useMemo(() => {
    return getImage(promotion[`banner_src_${prefix}`]);
  }, [promotion, prefix]);

  return (
    <Card className="card-promo-center" ref={PromotionCardRef}>
      {imageData && (
        <GatsbyImage
          image={imageData}
          alt={promotion.path}
          onClick={() => {
            trackPromotionCardImg(promotion.path);
            handleOnClick();
          }}
          className="dh-picture--promotion"
        />
      )}
      <Card.Body>
        <Card.Title className="mb-3 mt-0">{promotion[`description_${prefix}`]}</Card.Title>
        <div className="period p-2">
          {t('promotions_center.book_period')}
          {promotion.book_period && promotion.book_period.show ? <DateTimeBox dateTime={promotion.book_period} /> : '-'}
        </div>
        <div className="period p-2">
          {t('promotions_center.pickup_period')}
          {promotion.pickup_period && promotion.pickup_period.show ? (
            <DateTimeBox dateTime={promotion.pickup_period} />
          ) : (
            '-'
          )}
        </div>
        <Button
          variant="primary"
          as="a"
          href={`/${prefix}/promotions/${promotion.path}`}
          onClick={(e) => {
            e.preventDefault();
            trackPromotionCardMore(promotion.path);
            handleOnClick();
          }}
        >
          {t('promotions_center.see_description')}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PromotionCard;
