import { createRef, FunctionComponent, PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import moment from 'moment-timezone';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import PromotionCard from '../../components/card';
import { trackPromotionPageSideMenu } from './tracking';
// import { REGISTER_PROMOTION } from '@features';
import { PromotionProps, PromotionState, QueryResult, PromotionMapResult, SectionTH, SectionEN } from './interface';

import './style.scss';

const PromotionList: FunctionComponent<PromotionProps> = ({ t, prefix, i18n, tReady }) => (
  <StaticQuery
    query={graphql`
      query {
        promotions: allMarkdownRemark(
          filter: { fields: { collection: { eq: "promotions" } }, frontmatter: { activate: { eq: true } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                description_th
                description_en
                book_period {
                  from
                  to
                  show
                }
                pickup_period {
                  from
                  to
                  show
                }
                banner_src_th {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                banner_src_en {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                promotion_show
                kind
                path
                redirect_to_blog
                activate
                seo_title_th
                seo_title_en
              }
            }
          }
        }
      }
    `}
    render={(data: QueryResult) => {
      const promotions = data.promotions.edges
        .map(({ node }) => node.frontmatter)
        .filter((p) => {
          if (p.activate === false) return;
          if (p.promotion_show === false) return;
          return p;
        });
      // .sort((a, b) => moment(a.book_period.to, 'DD-MM-YYYY').unix() - moment(b.book_period.to, 'DD-MM-YYYY').unix());

      return <Promotions prefix={prefix} i18n={i18n} t={t} tReady={tReady} promotions={promotions} />;
    }}
  />
);

class Promotions extends PureComponent<PromotionProps, PromotionState> {
  state = {
    section: SectionTH.All,
    promotionList: this.props.promotions,
    promotions: this.props.promotions
  };
  // REF OBJ
  dropDownRef = createRef<HTMLSelectElement>();
  // REF OBJ
  promotionHeaderRef = createRef<HTMLDivElement>();
  promotionTitleRef = createRef<HTMLDivElement>();
  dropDownBoxRef = createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('scroll', this.handleScrollPage);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrollPage);
  }

  convertToEN = (section: SectionTH) => {
    switch (section) {
      case SectionTH.All:
        return SectionEN.All;
      case SectionTH.Coupons:
        return SectionEN.Coupons;
      case SectionTH.RentalPromotions:
        return SectionEN.RentalPromotions;
      case SectionTH.Other:
        return SectionEN.Other;
      default:
        return SectionEN.All;
    }
  };

  convertToTH = (section: SectionEN) => {
    switch (section) {
      case SectionEN.All:
        return SectionTH.All;
      case SectionEN.Coupons:
        return SectionTH.Coupons;
      case SectionEN.RentalPromotions:
        return SectionTH.RentalPromotions;
      case SectionEN.Other:
        return SectionTH.Other;
      default:
        return SectionTH.All;
    }
  };

  renderDropdownMenu = () => {
    const { t } = this.props;
    const menu = [SectionTH.All, SectionTH.RentalPromotions, SectionTH.Coupons, SectionTH.Other];
    const sectionTH = this.state.section;
    const list = (
      <select onChange={this.handleDropdownSelected} value={this.convertToEN(sectionTH)} ref={this.dropDownRef}>
        {menu.map((section: SectionTH, index: number) => {
          const sectionEN = this.convertToEN(section);
          return (
            <option key={index} value={sectionEN}>
              {t(`promotions_center.menu.${sectionEN}`)}
            </option>
          );
        })}
      </select>
    );

    return list;
  };

  renderSideMenu = () => {
    const { t } = this.props;
    const menu = [SectionTH.All, SectionTH.RentalPromotions, SectionTH.Coupons, SectionTH.Other];
    const list = menu.map((section: SectionTH, index: number) => {
      const sectionEN = this.convertToEN(section);
      return (
        <ListGroup.Item
          key={index}
          action
          onClick={() => this.handleClickOnSideMenu(sectionEN)}
          className={this.state.section === section ? 'active' : ''}
        >
          {t(`promotions_center.menu.${this.convertToEN(section)}`)}
        </ListGroup.Item>
      );
    });

    return list;
  };

  renderContent = () => {
    return this.state.promotions.length > 0 ? this.renderCard() : this.renderEmptyContent();
  };

  renderEmptyContent = () => {
    return (
      <div className="empty-section m-lg-0">
        <picture>
          <source type="image/webp" src="/assets/pages/promotions/empty.webp" />
          <img alt="empty" src="/assets/pages/promotions/empty.png" />
        </picture>
        <div className="mt-3">ไม่มีโปรโมชั่นและข่าวสาร</div>
      </div>
    );
  };

  renderCard = () => {
    const cards = this.state.promotions
      .sort((a, b) => {
        if (!a.book_period || !b.book_period) return 0;
        return moment(a.book_period.to, 'DD-MM-YYYY').unix() - moment(b.book_period.to, 'DD-MM-YYYY').unix();
      })
      .map((promotion: PromotionMapResult, index) => {
        return (
          <PromotionCard {...this.props} key={promotion.path} promotion={promotion} cardPosition={index.toString()} />
        );
      });

    return cards;
  };

  handleScrollPage = () => {
    if (!this.promotionHeaderRef.current || !this.promotionTitleRef.current || !this.dropDownBoxRef.current) return;

    if (window.pageYOffset >= 50 || window.scrollY >= 50) {
      this.promotionTitleRef.current.classList.add('hide');
      this.dropDownBoxRef.current.classList.add('sticky');
      this.promotionHeaderRef.current.classList.add('dark-blue');
    } else {
      this.promotionTitleRef.current.classList.remove('hide');
      this.promotionHeaderRef.current.classList.remove('dark-blue');
      this.dropDownBoxRef.current.classList.remove('sticky');
    }
  };

  handleClickOnSideMenu = (section: SectionEN) => {
    this.updateState(section);
    trackPromotionPageSideMenu(this.convertToTH(section));
  };

  handleDropdownSelected = (event: any) => {
    this.updateState(event.target.value);
    trackPromotionPageSideMenu(this.convertToTH(event.target.value));
  };

  updateState = (section: SectionEN) => {
    const promotions = this.getPromotionTarget(section);
    this.setState({
      section: this.convertToTH(section),
      promotions: promotions
    });
  };

  getPromotionTarget = (section: SectionEN) => {
    switch (section) {
      case SectionEN.All:
        return this.state.promotionList;
      default:
        return this.state.promotionList.filter((promotion) => this.convertToEN(promotion.kind) == section);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className="promotion-container">
        <div ref={this.promotionHeaderRef} className="header d-lg-none p-0 pb-4 sticky-top">
          <h3 ref={this.promotionTitleRef} className="mb-3">
            {t('promotions_center.header')}
          </h3>
          <div ref={this.dropDownBoxRef} className="dropdown">
            {this.renderDropdownMenu()}
            <i className="icon-down" />
          </div>
        </div>
        <Tab.Container>
          <Container className="p-3 pt-lg-5 pb-lg-5">
            <Row>
              <Col lg={3} className="d-none d-lg-block p-0 pr-4">
                <ListGroup>{this.renderSideMenu()}</ListGroup>
              </Col>
              <Col lg={9} className="cards-container p-0">
                {this.renderContent()}
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </div>
    );
  }
}

export default withTrans()(PromotionList);
