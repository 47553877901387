import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export const trackPromotionCardImg = (promotionName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.PromotionContent),
    event_action: 'click_card',
    event_label: promotionName
  });
};

export const trackPromotionCardMore = (promotionName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.PromotionContent),
    event_action: 'click_more_detail',
    event_label: promotionName
  });
};

interface PromotionDetailEcom {
  promotionID: string;
  promotionName: string;
  position: string;
  detail: string;
}
export const trackCarDealCardImpressionEcom = (promotionDetail: PromotionDetailEcom) => {
  const ecommerceData = {
    promoView: {
      promotions: [
        {
          id: `${promotionDetail.promotionID}`,
          name: `${promotionDetail.promotionName}`,
          creative: `${getDefaultEventCategory(PageSection.Promotion)}_slide_banner`,
          position: `${promotionDetail.position}`
        }
      ]
    }
  };
  sendToDataLayer(undefined, {
    event: 'ecommerce_promotionImpression',
    event_category: 'ecommerce',
    event_action: 'promotion_impression',
    event_label: `${promotionDetail.detail}`,
    ecommerce: ecommerceData
  });
};

export const trackCarDealCardClickEcom = (promotionDetail: PromotionDetailEcom) => {
  const ecommerceData = {
    promoClick: {
      promotions: [
        {
          id: `${promotionDetail.promotionID}`,
          name: `${promotionDetail.promotionName}`,
          creative: `${getDefaultEventCategory(PageSection.Promotion)}_card`,
          position: `${promotionDetail.position}`
        }
      ]
    }
  };
  sendToDataLayer(undefined, {
    event: 'ecommerce_promotionClick',
    event_category: 'ecommerce',
    event_action: 'promotion_click',
    event_label: `${promotionDetail.detail}`,
    ecommerce: ecommerceData
  });
};
